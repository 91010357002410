var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"b-dialog",attrs:{"persistent":"","transition":"dialog-bottom-transition","retain-focus":false,"value":_vm.dialog,"max-width":_vm.history ? 800 : 350},on:{"input":function($event){return _vm.closeModal(false)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","id":"btn-close","name":"btn-close"},on:{"click":function($event){return _vm.closeModal(false)}}},[_c('v-icon',[_vm._v("fa-solid fa-xmark")])],1)],1),(_vm.history)?_c('v-card-text',{staticClass:"b-dialog--content pt-5"},[_c('v-tabs',{staticClass:"b-tab-horizontal",attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1","id":"tab-tax"}},[_vm._v(" Taxas ")]),_c('v-tab',{attrs:{"href":"#tab-2","id":"tab-coverage"}},[_vm._v(" Coberturas ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-data-table',{staticClass:"mt-5",attrs:{"dense":"","disable-sort":"","item-key":"createdAt","headers":_vm.taxesHeaders,"items":_vm.taxes,"options":_vm.taxesOptions,"server-items-length":_vm.taxesTotalRecords,"items-per-page":10,"footer-props":{
              itemsPerPageOptions: [10, 20, 50, 100],
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'chevron_left',
              nextIcon: 'chevron_right'
            }},on:{"update:options":function($event){_vm.taxesOptions=$event}},scopedSlots:_vm._u([{key:`item.effectiveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.effectiveDate))+" ")]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-data-table',{staticClass:"mt-5",attrs:{"dense":"","disable-sort":"","item-key":"createdAt","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
              itemsPerPageOptions: [10, 20, 50, 100],
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'chevron_left',
              nextIcon: 'chevron_right'
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.coveragePercentage`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPercentage(item.coveragePercentage))+" ")]}},{key:`item.coverageValueLimit`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.coverageValueLimit))+" ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)})],1)],1)],1):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"b-dialog--content"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseAutonumeric',{attrs:{"id":"tax","name":"tax","suffix":"%","outlined":"","persistent-hint":"","required":"","rules":[_vm.required],"label":_vm.coverage.coverageRateType === 'Apólice'
                  ? 'Taxa de apólice'
                  : 'Taxa de cobertura',"hint":_vm.coverage.coverageRateType === 'Apólice'
                  ? `Valor atual: ${_vm.coverage.coveragePolicyRate}`
                  : `Valor atual: ${_vm.coverage.coverageRate}`,"options":{
                allowDecimalPadding: 'floats',
                decimalPlaces: '10'
              }},model:{value:(_vm.model.tax),callback:function ($$v) {_vm.$set(_vm.model, "tax", $$v)},expression:"model.tax"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BaseDatePicker',{attrs:{"id":"effectiveDate","name":"effectiveDate","label":"Data da vigência","required":"","rules":[_vm.required]},model:{value:(_vm.model.effectiveDate),callback:function ($$v) {_vm.$set(_vm.model, "effectiveDate", $$v)},expression:"model.effectiveDate"}})],1)],1)],1),_c('v-card-actions',{directives:[{name:"permission",rawName:"v-permission",value:({
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }),expression:"{\n          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n          operator: '==='\n        }"}],staticClass:"b-dialog--actions"},[_c('BaseButton',{attrs:{"id":"btn-save","name":"btn-save","type":"submit","color":"primary","title":"Salvar","disabled":!_vm.valid}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }