<template>
  <v-dialog
    persistent
    class="b-dialog"
    transition="dialog-bottom-transition"
    :retain-focus="false"
    :value="dialog"
    :max-width="history ? 800 : 350"
    @input="closeModal(false)"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon id="btn-close" name="btn-close" @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="b-dialog--content pt-5" v-if="history">
        <v-tabs show-arrows class="b-tab-horizontal" v-model="tab">
          <v-tab href="#tab-1" id="tab-tax"> Taxas </v-tab>
          <v-tab href="#tab-2" id="tab-coverage"> Coberturas </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-data-table
              dense
              disable-sort
              class="mt-5"
              item-key="createdAt"
              :headers="taxesHeaders"
              :items="taxes"
              :options.sync="taxesOptions"
              :server-items-length="taxesTotalRecords"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 50, 100],
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right'
              }"
            >
              <template v-slot:[`item.effectiveDate`]="{ item }">
                {{ formatDate(item.effectiveDate) }}
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-data-table
              dense
              disable-sort
              class="mt-5"
              item-key="createdAt"
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalRecords"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 50, 100],
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right'
              }"
            >
              <template v-slot:[`item.coveragePercentage`]="{ item }">
                {{ formatPercentage(item.coveragePercentage) }}
              </template>

              <template v-slot:[`item.coverageValueLimit`]="{ item }">
                {{ formatMoney(item.coverageValueLimit) }}
              </template>

              <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDate(item.createdAt) }}
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-form
        @submit.prevent="save"
        ref="form"
        lazy-validation
        v-model="valid"
        v-else
      >
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12">
              <BaseAutonumeric
                id="tax"
                name="tax"
                suffix="%"
                outlined
                persistent-hint
                required
                :rules="[required]"
                :label="
                  coverage.coverageRateType === 'Apólice'
                    ? 'Taxa de apólice'
                    : 'Taxa de cobertura'
                "
                :hint="
                  coverage.coverageRateType === 'Apólice'
                    ? `Valor atual: ${coverage.coveragePolicyRate}`
                    : `Valor atual: ${coverage.coverageRate}`
                "
                :options="{
                  allowDecimalPadding: 'floats',
                  decimalPlaces: '10'
                }"
                v-model="model.tax"
              />
            </v-col>
            <v-col cols="12">
              <BaseDatePicker
                id="effectiveDate"
                name="effectiveDate"
                label="Data da vigência"
                required
                :rules="[required]"
                v-model="model.effectiveDate"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions
          class="b-dialog--actions"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        >
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { ContractService } from '@/services/api/contracts';
import { showMessage } from '@/helpers/messages';
import {
  formatMoney,
  formatPercentage,
  formatDate
} from '@/helpers/formatting';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    history: {
      type: Boolean,
      required: true,
      default: false
    },
    customerId: {
      type: String
    },
    contractId: {
      type: String
    },
    coverage: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    tab: null,
    valid: true,
    model: {
      effectiveDate: null,
      tax: null
    },

    items: [],
    totalRecords: 0,
    options: {},
    headers: [
      { text: 'Cobertura', value: 'coverageName' },
      { text: 'Percentual', value: 'coveragePercentage' },
      { text: 'Valor limite', value: 'coverageValueLimit' },
      { text: 'Data de alteração', value: 'createdAt' }
    ],

    taxes: [],
    taxesTotalRecords: 0,
    taxesOptions: {},
    taxesHeaders: [
      { text: 'Taxa antiga', value: 'tax' },
      { text: 'Data de vigência', value: 'effectiveDate' }
    ]
  }),

  methods: {
    formatMoney,
    formatPercentage,
    formatDate,

    closeModal(search) {
      this.$emit('closeModal', search);
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const service = new ContractService();
        const { status } = await service.updateContractCoverageTax({
          contractId: this.contractId,
          customerId: this.customerId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getCoverages() {
      try {
        const { page, itemsPerPage } = this.options;
        const service = new ContractService();
        const { status, data } = await service.getCoverageReadjustments({
          page,
          take: itemsPerPage,
          contractId: this.contractId,
          customerId: this.customerId
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getCoverageTax() {
      try {
        const { page, itemsPerPage } = this.options;
        const service = new ContractService();
        const { status, data } = await service.getCoverageTax({
          page,
          take: itemsPerPage,
          contractId: this.contractId,
          customerId: this.customerId
        });

        if (status === 200) {
          this.taxes = data.results;
          this.taxesTotalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        if (this.history) {
          this.getCoverages();
        }
      },
      deep: true
    },

    taxesOptions: {
      handler() {
        if (this.history) {
          this.getCoverageTax();
        }
      },
      deep: true
    }
  }
};
</script>
